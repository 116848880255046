import React, {useState, useEffect, Fragment} from 'react';
import { Segment, Grid, Header, Icon, Divider, Form, Select, Table, Button } from "semantic-ui-react";
import feathers from "../../feathers-client";
import sabaths from "../../utils/sabaths";
import quarters from "../../utils/quarters";
import { currentQuarter, formatPercentage } from '../../utils/functions';
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);


const ReporteGraficas = () => {
  const [loading, setLoading] = useState(true);
  const [sabathsState, setSabaths] = useState(sabaths);
  const [dateSelected, setDateSelected] = useState(moment().day(6).format("YYYY-MM-DD"));
  const [quarterSelected, setQuarterSelected] = useState(currentQuarter);
  const [dataBarChart, setDataBarChart] = useState([])
  const [dataPieChart, setDataPieChart] = useState({})
  const [dataBarChartInf, setDataBarChartInf] = useState({})
  const [dataPieChartInf, setDataPieChartInf] = useState({})

  useEffect(() => {
    const sabathsFiltered = sabaths.filter(s => s.quarter === quarterSelected)
    setSabaths(sabathsFiltered)
    getReport(dateSelected, quarterSelected);
  }, [])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      datalabels: {
        font: {
          weight: 'bold',
          size: '18px'
        },
        formatter: function(value, context) {
          return Math.round(value) + '%';
        }
      }
    },
  };

  const chunkArray = (inputArray, perChunk) => {
    return inputArray.reduce((all,one,i) => {
      const ch = Math.floor(i/perChunk); 
      all[ch] = [].concat((all[ch]||[]),one); 
      return all
   }, [])
  }

  const getReport = (date, quarter) => {
    const report = feathers.service("reporte-graficas");
    report.find({
      query: {
        sabath: date,
        quarter: quarter
      }
    })
    .then(res => {
      console.log("🚀 ~ file: index.jsx:85 ~ getReport ~ res:", res)
      setLoading(false);
      const labelsChunked = chunkArray(res.nombresClase, 10);
      const promediosChunked = chunkArray(res.promediosClase, 10);
      for(let i = 0; i < labelsChunked.length; i++) {

      }
      setDataBarChart(
        labelsChunked.map((l, i) => ({
          labels: l,
          datasets: [{
            label: 'Estudio Diario',
            data: promediosChunked[i],
            backgroundColor: 'rgba(147, 196, 125, 1)',
          }]
        }))
      )
      setDataBarChartInf({
        labels: res.nombresClaseInf,
        datasets: [{
          label: 'Estudio Diario',
          data: res.promediosClaseInf,
          backgroundColor: 'rgba(147, 196, 125, 1)',
        }]
      })
      setDataPieChart({
        labels: ['Estudio Diario', 'No Estudio Diario'],
        datasets: [{
            label: 'Estudio Diario',
            data: res.promedioGeneral,
            backgroundColor: [
              'rgba(147, 196, 125, 1)',
              'rgba(224, 102, 102, 1)',
            ]
          }]
      })
      setDataPieChartInf({
        labels: ['Estudio Diario', 'No Estudio Diario'],
        datasets: [{
            label: 'Estudio Diario',
            data: res.promedioGeneralInf,
            backgroundColor: [
              'rgba(147, 196, 125, 1)',
              'rgba(224, 102, 102, 1)',
            ]
          }]
      })
    })
    .catch(err => {
      console.log(err);
    })
  }

  const handleChangeSabath = (event, result) => {
    const { name, value } = result || event.target;
    setLoading(true);
    setDateSelected(value)
    getReport(value, quarterSelected)
  }

  const handleChangeQuarter = (event, result) => {
    const { name, value } = result || event.target;
    const sabathsFiltered = sabaths.filter(s => s.quarter === value);
    setLoading(true);
    setQuarterSelected(value)
    setDateSelected(sabathsFiltered[0].value)
    getReport(sabathsFiltered[0].value, value)
  }

  return (
    <Fragment>
      <Segment loading={loading}>
          <Grid>
            <Grid.Column width={12}>
              <Header as="h1">
                <Icon name="file excel" />
                  <Header.Content>Reporte de Clases del {dateSelected}</Header.Content>
              </Header>
            </Grid.Column>
          </Grid>
          
          <Divider />

          <Grid style={{ padding: "0 20px"}}>
            <Grid.Row columns={4} textAlign="left">

              <Grid.Column>
                <Form.Field>
                  <Select
                    label="Trimestre"
                    value={quarterSelected}
                    options={quarters}
                    name="quarterSelected"
                    onChange={handleChangeQuarter}
                  />
                </Form.Field>
              </Grid.Column>
              
              <Grid.Column>
                <Form.Field>
                  <Select
                    label="Clase"
                    value={dateSelected}
                    options={sabathsState}
                    name="dateSelected"
                    onChange={handleChangeSabath}
                  />
                </Form.Field>
              </Grid.Column>
              
            </Grid.Row>
            <Grid.Row width={16}>

              
            </Grid.Row>
          </Grid>

          <Grid>
          <Header as="h1">
            <Header.Content>Clases de Adultos</Header.Content>
          </Header>
          {
          dataBarChart.length && dataBarChart.map((d, i) => (
            <Bar options={options} data={d} />
          ))
          }

          <Header as="h1">
            <Header.Content>Clases Infantiles</Header.Content>
          </Header>
          {
            dataBarChartInf.hasOwnProperty('labels') && <Bar options={options} data={dataBarChartInf} />
          }

          </Grid>

       

        <Grid style={{ "marginTop": '70px' }}>
          <Header as="h1">
              <Header.Content>Porcentaje de Estudio General del {dateSelected} - Adultos</Header.Content>
          </Header>

          <Grid style={{ "width": '75vh', "marginBottom": '50px' }}>

            {
              dataPieChart.hasOwnProperty('labels') &&
              <Pie options={options} data={dataPieChart} />
            }
          </Grid>

          <Header as="h1">
              <Header.Content>Porcentaje de Estudio General del {dateSelected} - Infantiles</Header.Content>
          </Header>

          <Grid style={{ "width": '75vh' }}>

            {
              dataPieChartInf.hasOwnProperty('labels') &&
              <Pie options={options} data={dataPieChartInf} />
            }
          </Grid>
        </Grid>
        
      </Segment>

    </Fragment>
  );
};

export default ReporteGraficas;
