const quarters = [
  {
    key: "2024Q4",
    text: "Trim 4 2024",
    value: "2024Q4"
  },
  {
    key: "2025Q1",
    text: "Trim 1 2025",
    value: "2025Q1"
  },
  {
    key: "2025Q2",
    text: "Trim 2 2025",
    value: "2025Q2"
  },
  {
    key: "2025Q3",
    text: "Trim 3 2025",
    value: "2025Q3"
  }
];

export default quarters;