import React, { useEffect, useState, useContext } from 'react';
import {
  Segment,
  Table, 
  Icon, 
  Checkbox, 
  Button,
  Form, 
  Header, 
  Grid, 
  Modal, 
  Dropdown,
  Label
} from 'semantic-ui-react';
import moment from 'moment';
import swal from "sweetalert";

import feathers from "../../feathers-client";
import sabaths from "../../utils/sabaths-gp";
import ListaGrupoContext from '../../Context/ListaGrupoContext/listaGrupoContext';
import {
  INITIAL_DATA,
  TYPE_DATA,
  AGE_DATA
} from '../../utils/initial-data-miembros'
import ReporteSemaforo from './ReporteSemaforo';
import { currentQuarter } from '../../utils/functions';

const PaseListaGrupo = ({ claseId, showSemaforo = true }) => {
  const listaGrupoContext = useContext(ListaGrupoContext);
  const { records, getRecords, updateRecords } = listaGrupoContext;

  const [loading, setLoading] = useState(false)
  const [sabathsState, setSabaths] = useState([]);
  const [dateSelected, setDateSelected] = useState(moment().day(6).format("YYYY-MM-DD"));
  const [currentDate, setCurrentDate] = useState(moment().day(6).format("YYYY-MM-DD"));
  const [isValidEdit, setIsValidEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newMiembro, setNewMiembro] = useState(INITIAL_DATA)
  const [currentClaseId, setCurrentClaseId] = useState(0)


  useEffect(() => {
    const sabathsFiltered = sabaths.filter(s => s.quarter === currentQuarter)
    const token = JSON.parse(localStorage.getItem("token"));
    setSabaths(sabathsFiltered);
    const currentClaseIdTemp = claseId ? claseId : token.clase_id
    getRecords(currentClaseIdTemp, dateSelected)
    setCurrentClaseId(currentClaseIdTemp)
  }, [claseId])

  const handleChangeDate = (event, result) => {
    const { name, value } = result || event.target;
    setIsValidEdit( value !== currentDate );
    setDateSelected(value);
    getRecords(currentClaseId, value);
  }

  const handleChangeCheckbox = (e, { checked, name, idCheck }) => {
    const tempRecords = [...records];
    tempRecords[idCheck] = {
      ...tempRecords[idCheck],
      [name]: checked ? "TRUE" : "FAlSE",
    }
    updateRecords(tempRecords);
  }

  const handleSubmit = () => {
    setLoading(true);
    const recordsTemp = [...records];
    for (let rec of recordsTemp) {
      delete rec.student;
      delete rec.created_at;
      rec.week_date = moment(rec.week_date).day(6).format("YYYY-MM-DD");

      if(!rec.id) {
        feathers.service('records-grupo')
        .create(rec)
          .then(res => {
            console.log("RECORD CREATED =>", res);
          })
      } else {
        feathers.service('records-grupo')
        .patch(rec.id, rec)
          .then(res => {
            console.log("RECORD UPDATED =>", res);
          })
      }
      getRecords(currentClaseId, dateSelected);
      setLoading(false);

      swal(
        "Registro guardado",
        "El registro de ha guardado correctamente.",
        "success",
        {timer: 1500}
      );
    }
  }

  const handleChange = (e) => {
    setNewMiembro({
      ...newMiembro,
      [e.target.name]: e.target.value
    })
  };

  const handleChangeSelect = (event, result) => {
    const { name, value } = result || event.target;
    setNewMiembro({
      ...newMiembro,
      [name]: value
    })
  };

  const handleAddStudent = () => {
    setLoading(true);
    const tempRecords = [...records];
    const tags = [newMiembro.type, newMiembro.age]
    const newMiembroTemp = {...newMiembro, tags: JSON.stringify(tags)};
    newMiembroTemp.clase_id = currentClaseId
    delete newMiembroTemp.type;
    delete newMiembroTemp.age;
    console.log(newMiembroTemp);

    feathers.service('students-grupo')
    .create(newMiembroTemp)
    .then(res => {
      console.log(res.id);
      const new_record = {
        miembro_id: res.id,
        miembro: {
          full_name: res.full_name,
          "type": newMiembro.type,
          "age": newMiembro.age
        },
        clase_id: newMiembroTemp.clase_id,
        assistence: "FALSE",
        week_date: dateSelected
      }
      tempRecords.push(new_record)
      updateRecords(tempRecords)
      setIsModalOpen(false)
      setNewMiembro(INITIAL_DATA)
      setLoading(false)
    })
  }

  return (
   <div>
     {/* SELECT LECCIÓN  */}
      <Grid>
        <Grid.Row columns={6}>
          <Grid.Column>
            <Header as="h3">
              <Icon name="calendar" />
              <Header.Content>
                <Dropdown
                  name="dateSelected"
                  options={sabathsState}
                  value={dateSelected}
                  onChange={handleChangeDate}
                />
                
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column textAlign="left" style={{ paddingTop: "5px" }}>
            <Header as="h5" textAlign="left">
              { dateSelected }
            </Header>
          </Grid.Column>
        </Grid.Row>
        
        <Grid.Row columns={3} className="help-icons">
          <Grid.Column>
            <p>
              <Icon name="clock" />
              Asistencia
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    
    {/* SEGMENT PASE DE LISTA */}
    <Segment loading={loading}>

      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={12}>
            <Header as="h3" textAlign="left" >
              <Header.Content>
                Lista de Miembros
              </Header.Content>
            </Header>
          </Grid.Column>
          
          <Grid.Column width={4} textAlign="right">
            <Button
              disabled={isValidEdit}
              size="mini" 
              positive 
              circular 
              icon="plus" 
              style={{ padding: "none" }}
              onClick={() => setIsModalOpen(true)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell width={12}>Miembro</Table.HeaderCell>
            {/* <Table.HeaderCell>Tags</Table.HeaderCell> */}
            <Table.HeaderCell width={4}>
              <Icon name="clock"/>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            records.map((r, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Label color={r.miembro.type === "MIEMBRO" ? 'green' : 'blue'} ribbon>
                    {r.miembro.type}
                  </Label>
                </Table.Cell>
                <Table.Cell>{r.miembro.full_name}</Table.Cell>
                {/* <Table.Cell>
                  <Label color='orange' tag>
                  {r.miembro.age}
                  </Label>
                </Table.Cell> */}
                <Table.Cell>
                  <Checkbox
                    disabled={isValidEdit}
                    name="assistence"
                    idCheck={i}
                    checked={r.assistence === "TRUE"}
                    onClick={handleChangeCheckbox}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row textAlign="right">
            <Table.HeaderCell colSpan='4'>
              <Button
                disabled={isValidEdit}
                content="Guardar Registro" 
                primary
                onClick={handleSubmit}
              />
              
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      {/* MODAL PARA AÑADIR MIEMBROS */}
      <Modal
       closeIcon
       open={isModalOpen}
       onClose={() => setIsModalOpen(false)}
      >
        <Modal.Header>
          Añadir Miembro
        </Modal.Header>
        <Modal.Content>
          <Form loading={loading}>

            <Form.Input
              required
              fluid
              name="full_name"
              label="Nombre del miembro"
              value={newMiembro.full_name}
              onChange={handleChange}
            />

            <Form.Input
              required
              fluid
              name="birthdate"
              label="Fecha de Nacimiento"
              value={newMiembro.birthdate}
              onChange={handleChange}
            />

            <Form.Select
              required
              fluid
              label="Miembro / Visita:"
              value={newMiembro.type}
              name="type"
              options={TYPE_DATA}
              onChange={handleChangeSelect}
            />

            <Form.Select
              required
              fluid
              label="Edad:"
              value={newMiembro.age}
              name="age"
              options={AGE_DATA}
              onChange={handleChangeSelect}
            />  

          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Form loading={loading} style={{ paddingBottom: "10px" }}>
            <Button
              disabled={ newMiembro.full_name === "" && !isValidEdit }
              primary
              content="Añadir"
              onClick={handleAddStudent}
            />
          </Form>
        </Modal.Actions>
      </Modal>
    
    </Segment>
    
    {/* REPORTE SEMAFORO */}
    <Segment loading={loading}>
      <Grid>
        <Grid.Row columns={2}>

          <Grid.Column width={12}>
            <Header as="h3" textAlign="left" >
              <Header.Content>
                Reporte de Trimestre
              </Header.Content>
            </Header>
          </Grid.Column>
          
        </Grid.Row>
      </Grid>
      {
        showSemaforo &&
        <ReporteSemaforo />

      }
    </Segment>
   </div>
  );
};

export default PaseListaGrupo;