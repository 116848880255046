import React, { Component } from "react";
import { Table, Icon, Checkbox, Button, Segment, Form, Header, Grid, Modal, Dropdown } from "semantic-ui-react"
import feathers from "../../feathers-client";
import swal from "sweetalert";
import _ from "lodash"
import moment from "moment";
import sabaths from "../../utils/sabaths"
import ReporteSemaforo from './ReporteSemaforo'
import { currentQuarter } from "../../utils/functions";

class PaseLista extends Component {

  state = {
    loadingStudents: false,
    loadingVisits: false,
    records: [],
    visits: [],
    newVisit: {
      full_name: "",
      cellphone: ""
    },
    newStudent: {
      full_name: "",
      clase_id: 0
    },
    isNewRecord: false,
    isModalOpen: false,
    isModalStudentOpen: false,
    currentUser: {},
    dateSelected: "",
    currentDate: "",
    isValidEdit: false,
    sabaths: []
  }

  componentDidMount() {
    // TODO: remove hardcoded "2021Q1"
    const sabathsFiltered = sabaths.filter(s => s.quarter === currentQuarter)
    const token = JSON.parse(localStorage.getItem("token"));
    this.setState({ 
      sabaths: sabathsFiltered,
      currentUser: token, 
      dateSelected: moment().day(6).format("YYYY-MM-DD"), 
      currentDate: moment().day(6).format("YYYY-MM-DD")}, 
      () => {
      this.getRecords(token.clase_id)
      this.getVisits(token.clase_id)
    })
    
  }

  getVisits = id => {
    const visits = feathers.service("visits")
    visits.find({
      query: {
        clase_id: id,
        week_date: this.state.dateSelected,
        $limit: 1000
      }
    })
    .then(res => {
      this.setState({ visits: res.data })
    })
  }

  getRecords = id => {
    const records = feathers.service('records')
    records.find({
      query: {
        clase_id: id,
        week_date: this.state.dateSelected,
        $limit: 1000
      }
    })
    .then(res => {
      const recordsSorted = [...res.data.filter(d => d.student.rol === 'MAESTRO'), ...res.data.filter(d => d.student.rol === 'ALUMNO')]
      this.setState({ records: recordsSorted }, () => {
        if(res.data.length === 0) {
          this.getRecordsFromStudents(id)
        }
      })
    })    
  }

  getRecordsFromStudents = id => {
    const students = feathers.service('students')
      students.find({
        query: {
          clase_id: id,
          $limit: 1000
        }
      })
      .then(res => {
        const newRecords = res.data.map(s => ({
          "student_id": s.id,
          "student" : {
            "full_name": s.full_name
          },
          "rol": s.rol,
          "trimestre": currentQuarter,
          "clase_id": s.clase_id,
          "week_date": this.state.dateSelected, //change by momentjs
          "study": "FALSE",
          "assistence": "FALSE",
          "verse": "FALSE",
        }))
        const newRecordsSorted = [...newRecords.filter(d => d.rol === 'MAESTRO'), ...newRecords.filter(d => d.rol === 'ALUMNO')]
        this.setState({ records: newRecordsSorted, isNewRecord: true })
      })
  }

  handleSubmit = async () => {
    this.setState({ loadingStudents: true })
    const records_temp = JSON.parse(JSON.stringify(this.state.records))
    for (let rec of records_temp) {
      delete rec.student
      delete rec.created_at
      delete rec.rol
      rec.week_date = moment(rec.week_date).day(6).format("YYYY-MM-DD")

      if(!rec.id) {
        await feathers.service('records')
        .create(rec)
        .then(res => {
        })
      } else {
        await feathers.service('records')
        .patch(rec.id, rec)
        .then(res => {
        })
      }
      
    }
    this.setState({ isNewRecord: false, loadingStudents: false })
    swal(
      "Registro guardado",
      "El registro de ha guardado correctamente.",
      "success",
      {timer: 1500}
    );
  }

  handleChangeCheckbox = (e, { checked, name, idCheck }) => {
    const tempRecords = [...this.state.records]
    tempRecords[idCheck] = {
      ...tempRecords[idCheck],
      [name]: checked ? "TRUE" : "FAlSE",
    }
    this.setState({records: tempRecords})
  }

  handleAddVisit = async () => {
    this.setState({ loadingVisits: true })
    const visits_temp = [...this.state.visits]
    const new_visit = {
      ...this.state.newVisit,
      week_date: this.state.dateSelected, //change by momentjs
      clase_id: this.state.currentUser.clase_id
    }
    visits_temp.push(new_visit)
    await feathers.service('visits')
      .create(new_visit)
      .then(res => {
      })
    this.setState({
      loadingVisits: false,
      visits: visits_temp, 
      isModalOpen: false, 
      newVisit: {
        full_name: "",
        cellphone: ""
      }
    })
  }

  handleAddStudent = async () => {
    this.setState({ loadingStudents: true })
    const records_temp = [...this.state.records]
    const new_student = {
      ...this.state.newStudent,
      clase_id: this.state.currentUser.clase_id
    }

    await feathers.service('students')
    .create(new_student)
    .then(async res => {
      const new_record = {
        student_id: res.id,
        student: {
          full_name: res.full_name
        },
        clase_id: new_student.clase_id,
        assistence: "FALSE",
        study: "FALSE",
        verse: "FALSE",
        week_date: this.state.dateSelected
      }
      records_temp.push(new_record)
      this.setState({ 
        records: records_temp, 
        isModalStudentOpen: false,
        newStudent: {
          full_name: "",
          clase_id: 0
        }
      })
    })
    this.setState({ loadingStudents: false })
  
  }

  handleChangeVisitForm = (e) => {
    this.setState({
      newVisit: {
        ...this.state.newVisit,
        [e.target.name]: e.target.value
      }
    })
  }

  handleChangeStudentForm = (e) => {
    this.setState({
      newStudent: {
        ...this.state.newStudent,
        [e.target.name]: e.target.value
      }
    })
  }

  handleDelete = async (index, visit_id) => {
    if(visit_id) {
      await feathers
        .service("visits")
        .remove(visit_id)
        .then(res => {
        })
        .catch(err => {
          console.log(err);
        });
    }
    const visits_temp = [...this.state.visits]
    visits_temp.splice(index, 1)
    this.setState({ visits: visits_temp })
  }

  handleChangeDate = (event, result) => {
    const { name, value } = result || event.target;
    this.setState({ isValidEdit: value !== this.state.currentDate })
    this.setState({ [name]: value, dateSelected: value, records: [] }, () => {
      this.getRecords(this.state.currentUser.clase_id)
      this.getVisits(this.state.currentUser.clase_id)
    })
  }

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h3">
                <Icon name="calendar" />
                <Header.Content>
                  <Dropdown
                    name="dateSelected"
                    options={this.state.sabaths}
                    value={this.state.dateSelected}
                    onChange={this.handleChangeDate}
                  />
                </Header.Content>
              </Header>
            </Grid.Column>
            
          </Grid.Row>
          <Grid.Row columns={3} className="help-icons">
            <Grid.Column>
              <p>
                <Icon name="clock" />
                Asistencia
              </p>
            </Grid.Column>
            <Grid.Column>
              <p>
                <Icon name="book" />
                Estudio Diario (min 6 días)
              </p>
            </Grid.Column>
            <Grid.Column>
              <p>
                <Icon name="check" />
                Versículo
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        
        <Segment loading={this.state.loadingStudents}>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <Header as="h3" textAlign="left" >
                  <Header.Content>
                    Lista de Alumnos
                  </Header.Content>
                </Header>
              </Grid.Column>
              
              <Grid.Column width={4} textAlign="right">
                <Button
                  disabled={this.state.isValidEdit}
                  size="mini" 
                  positive 
                  circular 
                  icon="plus" 
                  style={{ padding: "none" }}
                  onClick={() => this.setState({ isModalStudentOpen: true })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={10}>Alumno</Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <Icon name="clock"/>
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <Icon name="book"/>
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <Icon name="check"/>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                this.state.records.map((r, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{r.student.full_name}</Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        disabled={this.state.isValidEdit}
                        name="assistence"
                        idCheck={i}
                        checked={r.assistence === "TRUE"}
                        onClick={this.handleChangeCheckbox}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox 
                        disabled={this.state.isValidEdit}
                        name="study"
                        idCheck={i}
                        checked={r.study === "TRUE"}
                        onClick={this.handleChangeCheckbox}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox 
                        disabled={this.state.isValidEdit}
                        name="verse"
                        idCheck={i}
                        checked={r.verse === "TRUE"}
                        onClick={this.handleChangeCheckbox}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan='4'>
                  <Button
                    disabled={this.state.isValidEdit}
                    content="Guardar Registro" 
                    primary
                    onClick={this.handleSubmit}
                  >
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment>

        <Segment loading={this.state.loadingVisits}>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <Header as="h3" textAlign="left" >
                  <Header.Content>
                    Lista de Visitas
                  </Header.Content>
                </Header>
              </Grid.Column>
              
              <Grid.Column width={4} textAlign="right">
                <Button
                  disabled={this.state.isValidEdit}
                  size="mini" 
                  positive 
                  circular 
                  icon="plus" 
                  style={{ padding: "none" }}
                  onClick={() => this.setState({ isModalOpen: true })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Table unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Celular</Table.HeaderCell>
                <Table.HeaderCell>
                  <Icon name="trash"/>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                this.state.visits.map((v, i) => (
                  <Table.Row>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>
                      {v.full_name}
                    </Table.Cell>
                    <Table.Cell>
                      {v.cellphone}
                    </Table.Cell>
                    <Table.Cell>
                      <Icon
                        disabled={this.state.isValidEdit}
                        name="trash"
                        color="red"
                        onClick={() => this.handleDelete(i, v.id)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
        </Segment>

        <Segment>
          <Grid>
            <Grid.Row columns={2}>

              <Grid.Column width={12}>
                <Header as="h3" textAlign="left" >
                  <Header.Content>
                    Reporte de Trimestre
                  </Header.Content>
                </Header>
              </Grid.Column>
              
            </Grid.Row>
          </Grid>
          <ReporteSemaforo />
        </Segment>
        
        {/* Modal para añadir visita */}
        <Modal
          closeIcon
          open={this.state.isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
        >
          <Modal.Header>
            Añadir Visita
          </Modal.Header>
          <Modal.Content>
            <Form  loading={this.state.loadingVisits}>
              <Form.Input 
                required
                fluid
                label="Nombre"
                name="full_name"
                value={this.state.newVisit.full_name}
                onChange={this.handleChangeVisitForm}
              />
              <Form.Input 
                required
                fluid
                label="Celular"
                name="cellphone"
                value={this.state.newVisit.cellphone}
                onChange={this.handleChangeVisitForm}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Form loading={this.state.loadingVisits} style={{ paddingBottom: "10px" }}>
              <Button
                disabled={this.state.newVisit.full_name === "" || this.state.newVisit.cellphone === "" ||this.state.isValidEdit }
                primary
                content="Añadir"
                onClick={this.handleAddVisit}
              />
            </Form>
          </Modal.Actions>
        </Modal>

        {/* Modal para añadir alumno */}
        <Modal
          closeIcon
          open={this.state.isModalStudentOpen}
          onClose={() => this.setState({ isModalStudentOpen: false })}
        >
          <Modal.Header>
            Añadir Alumno
          </Modal.Header>
          <Modal.Content>
            <Form loading={this.state.loadingStudents}>
              <Form.Input 
                required
                fluid
                label="Nombre"
                name="full_name"
                value={this.state.newStudent.full_name}
                onChange={this.handleChangeStudentForm}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Form loading={this.state.loadingStudents} style={{ paddingBottom: "10px" }}>

              <Button
                disabled={this.state.newStudent.full_name === "" ||this.state.isValidEdit }
                primary
                content="Añadir"
                onClick={this.handleAddStudent}
              />
            </Form>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default PaseLista;
