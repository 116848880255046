import React, { Component } from "react";
import {
  Segment,
  Grid,
  Table,
  Header,
  Divider,
  Icon,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import feathers from "../../feathers-client";
import swal from "sweetalert";

class ListaClases extends Component {
  state = {
    clases: [],
  }

  componentDidMount() {
    this.getClases()
  }

  getClases = () => {
    const clases = feathers.service("clases");
    clases.find({
      query: {
        $limit: 1000
      }
    })
      .then(res => {
        this.setState({clases: res.data})
      })
  }

  handleDelete = Id => {
    swal({
      title: "¿Seguro desea eliminar la clase?",
      text:
        "Esta operación es irreversible",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async willDelete => {
      if (willDelete) {
        await feathers
          .service("clases")
          .remove(Id)
          .then(res => {
            swal("Clase eliminada correctamente", { icon: "success", timer: 1500 });
            this.getClases();
          })
          .catch(err => {
            swal("Ocurrió un error. Intente más tarde", { icon: "error" });
          });
      }
    });
  };

  render() {

    return(
      <Segment>
        <Grid>
          <Grid.Column width={12}>
            <Header as="h1">
              <Icon name="list" />
              <Header.Content>Listado de Clases</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Link to="crear-clase">
            <Icon name='plus' size="big" color="green"/>
            </Link>
          </Grid.Column>
        </Grid>
         
        <Divider />
        <Grid  style={{ padding: "0 20px"}}>
          <Grid.Row width={16}>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Acciones</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.clases.map((c, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>{c.name}</Table.Cell>
                    <Table.Cell>
                      <Link to="#" onClick={() => this.handleDelete(c.id)}>
                        <Icon name="trash" color="grey" /> Eliminar
                      </Link>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Link to={`/editar-clase/${c.id}`}>
                        <Icon name="pencil" color="grey" /> Editar
                      </Link>                      
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }
}

export default ListaClases;