import React, { Component } from "react";
import {
  Container,
  Icon,
  Image,
  Menu,
  Sidebar,
  Dropdown
} from "semantic-ui-react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { useState } from "react";

const handleCerrarSesion = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("tokenjwt");
  window.location = "/login";
};

const NavBarMobile = ({
  children,
  onPusherClick,
  onToggle,
  visible,
  userLogged,
  isAdmin,
  isTeacher
}) => (
  <Sidebar.Pushable>
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      vertical
      visible={visible}
      direction="right"
    >
      {
        isAdmin && (
          <Menu.Item
            style={{ fontWeight: "bold" }}
          >
            {userLogged}
          </Menu.Item>
        )
      }

      {
        isAdmin && (
          <Menu.Item
            as={Link}
            to="/alumnos"
            name='students'
            onClick={onToggle}
          >
            Alumnos
          </Menu.Item>
        )
      }

      {
        isAdmin && (
          <Menu.Item
            as={Link}
            to="/miembros-gp"
            name='miembros-gp'
            onClick={onToggle}
          >
            Miembros GP
          </Menu.Item>
        )
      }
      
      {
        isAdmin && (
          <Menu.Item
            as={Link}
            to="/maestros"
            name='users'
            onClick={onToggle}
          >
            Maestros
          </Menu.Item>
        )
      }
      
      {
        isAdmin && (
          <Menu.Item
            as={Link}
            to="/clases"
            name='clases'
            onClick={onToggle}
          >
            Clases
          </Menu.Item>
        )
      }

      {
        isAdmin && (
          <Menu.Item
            as={Link}
            to="/reporte"
            name='report'
            onClick={onToggle}
          >
            Reporte General
          </Menu.Item>
        )
      }

{
        isAdmin && (
          <Menu.Item
            as={Link}
            to="/reporte-clase"
            name='reporte-clase'
            onClick={onToggle}
          >
            Reporte Clases
          </Menu.Item>
        )
      }

      {
        isTeacher && (
          <Menu.Item
            as={Link}
            to="/pase-lista"
            name='paselista'
            onClick={onToggle}
          >
            Pase de Lista
          </Menu.Item>
        )
      }

      {
        isTeacher && (
          <Menu.Item
            disabled
            as={Link}
            to="/pase-lista-grupo"
            name='paselistagrupo'
            onClick={onToggle}
          >
            Pase de Lista
          </Menu.Item>
        )
      }

      {
        (
          <Menu.Item
            disabled
            as={Link}
            to="/datos-grupo"
            name='datosgrupo'
            onClick={onToggle}
          >
            Datos Grupo Pequeño
          </Menu.Item>
        )
      }

      {
        (
          <Menu.Item
            as={Link}
            to="/datos-grupo"
            name='datosgrupo'
            onClick={handleCerrarSesion}
          >
            <Icon name='power off' />
            Cerrar Sesión
          </Menu.Item>
        )
      }
      
    </Sidebar>
    <Sidebar.Pusher
      dimmed={visible}
      onClick={onPusherClick}
      style={{ minHeight: "100vh" }}
    >
      <Menu fixed="top" inverted>
        <Menu.Item>
          <Image size="tiny" src="/iasd.png" />
        </Menu.Item>

        <Menu.Menu position="right">
          <Menu.Item onClick={onToggle}>
            <Icon name="sidebar" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {children}
    </Sidebar.Pusher>
  </Sidebar.Pushable>
);

const NavBarDesktop = ({ userLogged, isAdmin, isTeacher }) => {
  const [activeItem, setActiveItem] = useState("students")

  const handleItemClick = (e, { name }) => setActiveItem(name)

  return (
  <Menu fixed="top" inverted>
    <Menu.Item>
      <Image size="tiny" src="/iasd.png" />
    </Menu.Item>

      {
        isAdmin && (
          <Dropdown item text="Administración">
            <Dropdown.Menu>
              <Dropdown.Item
                disabled
                content="Escuela Sabática"
                className="dropdown-header"
              />
              <Dropdown.Item
                text="Clases"
                as={Link}
                to="/clases"
              />
               <Dropdown.Item
                text="Maestros ES"
                as={Link}
                to="/maestros"
              />
              <Dropdown.Item
                text="Alumnos ES"
                as={Link}
                to="/alumnos"
              />
              <Dropdown.Item
                disabled
                content="Ministerio Personal"
                className="dropdown-header"
              />
              <Dropdown.Item
                disabled
                text="Grupos Pequeños"
                as={Link}
                to="/datos-grupo"
              />
              <Dropdown.Item
                disabled
                text="Líderes Misioneros"
                as={Link}
                to="/miembros-gp"
              />
              <Dropdown.Item
                disabled
                text="Miembros GP"
                as={Link}
                to="/miembros-gp"
              />
             
              
            </Dropdown.Menu>
          </Dropdown>
        )
      }
      
      {
        isAdmin && (
          <Dropdown item text="Reportes">
            <Dropdown.Menu>
              <Dropdown.Item
                disabled
                content="Escuela Sabática"
                className="dropdown-header"
              />
              <Dropdown.Item
                text="General ES"
                as={Link}
                to="/reporte"
              />
              <Dropdown.Item
                text="Clases ES"
                as={Link}
                to="/reporte-clase"
              />
              <Dropdown.Item
                text="Graficas"
                as={Link}
                to="/reporte-graficas"
              />
              <Dropdown.Item
                disabled
                content="Grupos Pequeños"
                className="dropdown-header"
              />
              <Dropdown.Item
                text="General GP"
                as={Link}
                to="/group/reporte-general"
              />
              <Dropdown.Item
                text="Clases GP"
                as={Link}
                to="/reporte-clase-gp"
              />
              <Dropdown.Item
                text="Objetivos"
                as={Link}
                to="/group/objetivos/reporte"
              />
            </Dropdown.Menu>
          </Dropdown>
        )
      }

      {
        isAdmin && (
          <Menu.Item
            as={Link}
            to="/intereses"
            name='intereses'
            active={activeItem === 'intereses'}
            onClick={handleItemClick}
          >
            Intereses
          </Menu.Item>
        )
      }

      {
        isTeacher && (
          <Menu.Item
            as={Link}
            to="/pase-lista"
            name='paselista'
            active={activeItem === 'paselista'}
            onClick={handleItemClick}
          >
            Pase de Lista
          </Menu.Item>
        )
      }

      {
        isTeacher && (
          <Menu.Item
            as={Link}
            to="/pase-lista-grupo"
            name='paselistagrupo'
            active={activeItem === 'paselistagrupo'}
            onClick={handleItemClick}
          >
            Pase de Lista GP
          </Menu.Item>
        )
      }

      {
        isTeacher && (
          <Menu.Item
            as={Link}
            to="/group/objetivos"
            name='objetivos'
            active={activeItem === 'objetivos'}
            onClick={handleItemClick}
          >
            Objetivos
          </Menu.Item>
        )
      }

      {
        isTeacher && (
          <Menu.Item
            as={Link}
            to="/datos-grupo"
            name='datosgrupo'
            active={activeItem === 'datosgrupo'}
            onClick={handleItemClick}
          >
            Datos Grupo Pequeño
          </Menu.Item>
        )
      }

      {
        isTeacher && (
          <Menu.Item
            as={Link}
            to="/intereses"
            name='intereses'
            active={activeItem === 'intereses'}
            onClick={handleItemClick}
          >
            Intereses
          </Menu.Item>
        )
      }

    <Menu.Menu position="right">
      <Dropdown item text={userLogged}>
        <Dropdown.Menu>
          <Dropdown.Item
            icon="power off"
            text="Cerrar Sesion"
            onClick={handleCerrarSesion}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Menu>
  </Menu>
)};

const NavBarChildren = ({ children }) => (
  <Container style={{ marginTop: "7em" }}>{children}</Container>
);


class NavBar extends Component {
  state = {
    visible: false
  };

  handlePusher = () => {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false });
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  render() {
    const { children, userLogged , isAdmin, isTeacher} = this.props;
    const { visible } = this.state;

    return (
      <div>
        <MediaQuery query="(max-width: 768px)">

          <NavBarMobile
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            visible={visible}
            userLogged={userLogged}
            isAdmin={isAdmin}
            isTeacher={isTeacher}
            >
            <NavBarChildren>{children}</NavBarChildren>
          </NavBarMobile>
        </MediaQuery>
        
        <MediaQuery query="(min-width: 769px)">
          <NavBarDesktop userLogged={userLogged} isAdmin={isAdmin} isTeacher={isTeacher}/>
          <NavBarChildren>{children}</NavBarChildren>
        </MediaQuery>
      </div>
    );
  }
}

export default NavBar;