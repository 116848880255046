import React, { useReducer } from 'react';
import ListaGrupoContext from './listaGrupoContext';
import ListaGrupoReducer from './listaGrupoReducer';
import feathers from "../../feathers-client"
import {
  GET_RECORDS_GRUPO,
  UPDATE_RECORDS
} from '../types'
import _ from 'lodash';
import { currentQuarter } from '../../utils/functions';

const ListaGrupoState = props => {
  const initialState = {
    records: []
  }

  const [state, dispatch] = useReducer(ListaGrupoReducer, initialState)

  //GET records
  const getRecords = (claseId, dateSelected) => {
    const records = feathers.service('records-grupo');
    records.find({
      query: {
        clase_id: claseId,
        week_date: dateSelected,
        $limit: 1000
      }
    })
    .then(res => {
      let recordsFormatted = res.data.map(r => {
        const tags = JSON.parse(r.miembro.tags);
        return {
          ...r,
          miembro: {
            ...r.miembro,
            type: tags[0],
            age: tags[1]
          }
        }
      })
      recordsFormatted = _.sortBy(recordsFormatted, ['miembro.type', 'miembro.age']);
      dispatch({
        type: GET_RECORDS_GRUPO,
        payload: recordsFormatted
      })
      if(res.data.length === 0) {
        getRecordsFromMiembros(claseId, dateSelected)
      }
    })  
  }

  //GET records from students
  const getRecordsFromMiembros = (claseId, dateSelected) => {
    const students = feathers.service('students-grupo')
    students.find({
      query: {
        clase_id: claseId,
        $limit: 1000
      }
    })
    .then(res => {
      let newRecords = res.data.map(s => {
        const tags = JSON.parse(s.tags);
        return {
          "miembro_id": s.id,
          "miembro" : {
            "full_name": s.full_name,
            "type": tags[0],
            "age": tags[1]
          },
          "trimestre": currentQuarter,
          "clase_id": s.clase_id,
          "week_date": dateSelected, //change by momentjs
          "assistence": "FALSE",
          }
      })
      newRecords = _.sortBy(newRecords, ['miembro.type', 'miembro.age']);
      dispatch({
        type: GET_RECORDS_GRUPO,
        payload: newRecords
      })
    })
  }

  // update records when checkbox changed
  const updateRecords = (records) => {
    dispatch({
      type: UPDATE_RECORDS,
      payload: records
    })
  }

  return <ListaGrupoContext.Provider
    value={{
      records: state.records,
      getRecords,
      updateRecords
    }}
  >
    { props.children }
  </ListaGrupoContext.Provider>
};

export default ListaGrupoState;
