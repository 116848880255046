import React, { useState, useEffect } from 'react';
import { 
  Segment,
  Grid,
  Header,
  Icon,
  Table,
  Divider
} from 'semantic-ui-react';
import feathers from "../../../feathers-client";
import { currentQuarter, formatPercentage } from '../../../utils/functions';
import sabaths from "../../../utils/sabaths"

const ReporteSemaforo = ({claseId}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [sabathsState, setSabathsState] = useState([])

  useEffect(() => {
    setLoading(true)
    const sabathsFiltered = sabaths.filter(s => s.quarter === currentQuarter)
    setSabathsState(sabathsFiltered)
    if(claseId) {
      feathers.service("reporte-clase-grupo").get(claseId)
      .then(res => {
        setData(res[0])
        setLoading(false)
      })
      .catch(err => {
        console.log("ERROR GET REPORTE SEMAFORO REPORTE CLASE GP", err);
      })
    }
  }, [claseId])

  return (
    <Segment loading={loading}>
      <Grid>
        <Grid.Column width={12}>
          <Header as="h1">
            <Icon name="file" />
              <Header.Content>Reporte general</Header.Content>
          </Header>
        </Grid.Column>
      </Grid>
      
      <Divider />

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
            </Table.HeaderCell>
            {
              sabathsState.map(m => 
                <Table.HeaderCell>
                  {m.text.substring(8,10)}
                </Table.HeaderCell>
              )
            }
            <Table.HeaderCell>
              Promedio General
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>

          <Table.Row>
            <Table.Cell><Icon name="clock" /><strong>A</strong></Table.Cell>
            {
              data.map((cell) => (
                cell === "N/A" 
                  ?
                    <Table.Cell></Table.Cell>
                  :
                    <Table.Cell positive={cell>=0.80} warning={cell>=0.60&&cell<0.80} negative={cell<0.60}>
                      {formatPercentage(cell) + "%"}
                    </Table.Cell>
              ))
            }
          </Table.Row>

        </Table.Body>
      </Table>
    </Segment>
  )
};

export default ReporteSemaforo;